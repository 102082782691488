import React from "react";

const Preloader = () => {
  return (
    <div class="preeloader">
      <div class="preloader-spinner"></div>
    </div>
  );
};

export default Preloader;
