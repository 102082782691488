import React from "react";

const Hero = () => {
  return (
    <div className="welcome-slider-area">
      <div className="welcome-single-slide slide-one">
        <div className="slider-bg"></div>
        <div className="container no-padding">
          <div className="welcome-text text-center">
            <h1>
              We Make <span>Your</span> Dream True
            </h1>
            <p>100% sequere and trusted plaing</p>
          </div>
        </div>
      </div>
      <div className="welcome-single-slide slide-two">
        <div className="slider-bg"></div>
        <div className="container no-padding">
          <div className="welcome-text text-center">
            <h1>
              Best <span>Service</span> Available Here
            </h1>
            <p>We Make Your Dream Home</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
