import React from "react";

const Contact = () => {
  return (
    <section className="contact-quote-area section-padding" id="quote">
      <div className="quote-area-bg" data-stellar-background-ratio="0.6"></div>
      <div className="container">
        <div className="row">
          <div
            className="
          col-md-8 col-lg-8 col-md-offset-2 col-lg-offset-2 col-sm-12 col-xs-12
        "
          >
            <div className="quote-content text-center wow fadeIn">
              <h2>Core Vision</h2>
              <p>
                We will be the provider of choice, leveraging our collective
                talents to meet the needs of our diverse client base.
              </p>
              <a
                href="#contact-form-modal"
                className="contact-quate-button"
                data-toggle="modal"
                data-target="#contact-form-modal"
              >
                Get A Quate <i className="bi bi-arrow-left-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
