import React from "react";
import { servicesData } from "./content/servicesData";

const Services = () => {
  return (
    <section className="service-area gray-bg section-padding" id="service">
      <div className="service-area-bg"></div>
      <div className="container">
        <div className="row">
          <div
            className="
          col-md-6 col-lg-6 col-md-offset-3 col-lg-offset-3 col-sm-12 col-xs-12
        "
          >
            <div className="area-title text-center wow fadeIn">
              <h2>Our Services</h2>
              <p>
                Deliver world-class facility-related services for our clients'
                success.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {servicesData.map((service) => (
            <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12">
              <div
                className="single-service text-center wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="service-hidding-and-icon">
                  <div className="service-icon">
                    <i className={`${service.icon}`}></i>
                  </div>
                  <h3>{service?.title}</h3>
                </div>
                <div className="service-details">
                  <div className="service-icon">
                    <i className="<%= service.icon %>"></i>
                  </div>
                  <h3>{service?.title}</h3>
                  <p>{service?.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
