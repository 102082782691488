import React from "react";
import { Link } from "react-router-dom";
import useCurrentYear from "../hooks/useCurrentYear";

const CopyRight = () => {
  const currentYear = useCurrentYear();

  return (
    <div className="footer-bottom-area">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div className="footer-copyright text-center wow fadeIn">
              <p>
                Copyright &copy;
                <Link className="company-link" to="https://deapex.co.za">
                  {" "}
                  Deapex
                </Link>
                2021 - {currentYear} All Right Reserved. Made By
                <Link className="company-link" to="https://afridek.software">
                  {" "}
                  Afridek Software
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyRight;
