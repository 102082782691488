import React from "react";
import { Button, Form } from "react-bootstrap";

const ContactForm = () => {
  return (
    <Form>
      <div
        className="modal fade"
        id="contact-form-modal"
        tabindex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <Button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </Button>
              <h4 className="modal-title">Get in touch with us</h4>
            </div>
            <div className="modal-body">
              <div className="contact-form">
                <div className="form-group" id="name-field">
                  <div className="form-input">
                    <input
                      type="text"
                      className="form-control"
                      name="fullNames"
                      placeholder="Enter full names..."
                      required
                    />
                  </div>
                </div>
                <div className="form-group" id="email-field">
                  <div className="form-input">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Enter email..."
                      required
                    />
                  </div>
                </div>
                <div className="form-group" id="phone-field">
                  <div className="form-input">
                    <input
                      type="tel"
                      className="form-control"
                      name="mobile"
                      placeholder="Enter mobile number..."
                      required
                    />
                  </div>
                </div>
                <div className="form-group" id="message-field">
                  <div className="form-input">
                    <textarea
                      className="form-control"
                      rows="6"
                      name="message"
                      placeholder="Enter message..."
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit">
                    Send Message
                    <i className="bi bi-paper-plane"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ContactForm;
