import React from "react";
import Hero from "./components/Hero/Hero";

const Header = () => {
  const handleScrollToSection = (event, sectionId) => {
    event.preventDefault();
    document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <header className="top-area" id="home">
      <div className="header-top-area">
        <div className="mainmenu-area" id="mainmenu-area">
          <div className="mainmenu-area-bg"></div>
          <nav className="navbar">
            <div className="container">
              <div className="navbar-header">
                <button
                  className="collapsed navbar-toggle"
                  type="button"
                  data-toggle="collapse"
                  data-target=".bs-example-js-navbar-scrollspy"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <a
                  href="#home"
                  className="navbar-brand"
                  onClick={(e) => handleScrollToSection(e, "home")}
                >
                  <img
                    src="img/logo.png"
                    alt=""
                    style={{
                      width: "48px",
                    }}
                  />
                </a>
              </div>
              <div className="collapse navbar-collapse bs-example-js-navbar-scrollspy text-center">
                <div className="side-push-menu">
                  <div className="push-menu-open-button">
                    <i className="bi bi-justify-left"></i>
                  </div>
                </div>
                <ul id="nav" className="nav navbar-nav">
                  <li>
                    <a
                      href="#home"
                      onClick={(e) => handleScrollToSection(e, "home")}
                    >
                      home
                    </a>
                  </li>
                  <li>
                    <a
                      href="#about"
                      onClick={(e) => handleScrollToSection(e, "about")}
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <a
                      href="#service"
                      onClick={(e) => handleScrollToSection(e, "service")}
                    >
                      service
                    </a>
                  </li>
                  <li>
                    <a
                      href="#project"
                      onClick={(e) => handleScrollToSection(e, "project")}
                    >
                      project
                    </a>
                  </li>
                  <li>
                    <a
                      href="#team"
                      onClick={(e) => handleScrollToSection(e, "team")}
                    >
                      team
                    </a>
                  </li>
                  <li>
                    <a
                      href="#contact-form-modal"
                      className="contact-button"
                      data-toggle="modal"
                      data-target="#contact-form-modal"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <Hero />
    </header>
  );
};

export default Header;
