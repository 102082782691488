import React from "react";
import { Link } from "react-router-dom";

const PushMenu = () => {
  return (
    <div className="push-menu-and-content">
      <div className="push-menu-close">
        <i className="bi bi-close"></i>
      </div>
      <div className="push-menu-logo">
        <Link to="#home">
          <img src="img/footer-logo.png" alt="" />
        </Link>
      </div>
      <div className="push-menu">
        <ul>
          <li className="active">
            <Link to="#home"> Home </Link>
          </li>
          <li>
            <Link to="#about">About</Link>
          </li>
          <li>
            <Link to="#service">Service</Link>
          </li>
          <li>
            <Link to="#project">Project</Link>
          </li>
          <li>
            <Link to="#team">Team</Link>
          </li>
          <li>
            <Link
              to="#contact-form-modal"
              className="contact-button"
              data-toggle="modal"
              data-target="#contact-form-modal"
            >
              Contac
            </Link>
          </li>
        </ul>
      </div>
      <div className="push-img-and-about-content">
        <div className="about-autor">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat
            quod vel vero neque laborum, aspernatur quas quis rerum, placeat
            ipsa!
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat
            quod vel vero neque laborum, aspernatur quas quis rerum, placeat
            ipsa!
          </p>
        </div>
      </div>
      <div className="social-bookmark">
        <ul>
          <li>
            <Link to="#">
              <i className="bi bi-facebook"></i>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="bi bi-instagram"></i>
            </Link>
          </li>
          <li>
            <Link to="#">
              <i className="bi bi-linkedin"></i>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PushMenu;
