import React from "react";
import { Link } from "react-router-dom";
import CopyRight from "./components/CopyRight";
import ContactForm from "./components/ContactForm";

const Footer = () => {
  return (
    <div className="footer-area">
      <div className="footer-area-bg"></div>
      <div className="contact-form-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-top-area dark-bg section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
              <div className="single-footer-widget fotter-logo-and-content wow fadeIn">
                <div className="footer-logo">
                  <Link to="index.html">
                    <img
                      src="img/footer-logo.png"
                      alt=""
                      style={{
                        height: "49px",
                      }}
                    />
                  </Link>
                </div>
                <p>
                  Deapex Construction works and project, is a 21 st century
                  company, and prides itself in its highly effective and
                  streamlined methodology for conducting business.
                </p>
                <ul className="social-bookmark">
                  <li>
                    <Link to="#">
                      <i className="bi bi-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="bi bi-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="bi bi-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12">
              <div className="single-footer-widget wow fadeIn">
                <h4>Quick Links</h4>
                <ul className="footer-menu">
                  <li>
                    <Link to="#home">Home</Link>
                  </li>
                  <li>
                    <Link to="#about">About</Link>
                  </li>
                  <li>
                    <Link to="#services">Services</Link>
                  </li>
                  <li>
                    <Link to="#projects">Projects</Link>
                  </li>
                  <li>
                    <Link to="#team">Team</Link>
                  </li>
                  <li>
                    <Link to="#contact-form-modal">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12">
              <div className="single-footer-widget wow fadeIn">
                <h4>Contact Us</h4>
                <ul className="contact-details">
                  <li>
                    <div className="f_contact_icon">
                      <i className="bi bi-phone"></i>
                    </div>
                    <p>
                      <Link to="callto:+27798996897">+27 79 899 6897</Link>
                    </p>
                  </li>
                  <li>
                    <div className="f_contact_icon">
                      <i className="bi bi-inbox"></i>
                    </div>
                    <p>
                      <Link to="fax:+27864857804">+27 86 485 7804</Link>
                    </p>
                  </li>
                  <li>
                    <div className="f_contact_icon">
                      <i className="bi bi-envelope"></i>
                    </div>
                    <p>
                      <Link to="mailto:info@deapex.co.za">
                        info@deapex.co.za
                      </Link>
                    </p>
                  </li>
                  <li>
                    <div className="f_contact_icon">
                      <i className="bi bi-geo-fill"></i>
                    </div>
                    <p>
                      Stand Number 123 Mogwase, Unit 8 ,Rustenburg 0314, South
                      Africa
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CopyRight />
    </div>
  );
};

export default Footer;
