import React from "react";

const About = () => {
  return (
    <section className="about-area section-padding" id="about">
      <div className="container about-features">
        <div className="row">
          <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12">
            <div className="single-features">
              <div className="features-icon">
                <i className="bi bi-peace-fill"></i>
              </div>
              <h3>Employment</h3>
              <p>
                We believe that talented, inspired and motivated people can
                accomplish extraordinary results. In order to accomplish this
                goal, we must select, develop and retain the right people.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12">
            <div className="single-features">
              <div className="features-icon">
                <i className="bi bi-tools"></i>
              </div>
              <h3>Strong Values</h3>
              <p>
                We are guided by values and a culture of success established by
                our founders. These values guide us in all we do:
              </p>
            </div>
          </div>
          <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12">
            <div className="single-features">
              <div className="features-icon">
                <i className="bi bi-award-fill"></i>
              </div>
              <h3>Our Success</h3>
              <p>
                Our success depends on the achievement of each individual in our
                company. We will provide challenges and opportunities, wherever
                possible for our employees in order to foster positivepersonal
                and technical growth.
              </p>
            </div>
          </div>
          <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12">
            <div className="single-features">
              <div className="features-icon">
                <i className="bi bi-headset"></i>
              </div>
              <h3>Our Concrete Services</h3>
              <p>
                is a team of experienced professionals and highly qualified
                tradesmen that provide superior concrete construction.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container about-bottom-content">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div className="about-img">
              <div className="about-layer-one">
                <img src="/img/slider/slide_1.jpg" alt="" /> 
              </div>
              <div className="about-layer-two">
                <img src="/img/slider/slide_2.jpg" alt="" /> 
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-5 col-sm-12 col-xs-12">
            <div className="about-content wow fadeInRight">
              <h2>
                <span className="company-about">About</span>
                <span className="company-name">Deapex</span>
                <span className="company-about">.</span>
              </h2>
              <p>
                De – Apex Construction works and project, is a 21 st century
                company, and prides itself in its highly effective and
                streamlined methodology for conducting business. We have a
                strong in-house management team of construction professional and
                approved specialist’s subcontractors available to us operating
                throughout South Africa.
              </p>
              <p>
                We work in partnership with highly experienced construction
                designers whose selection criteria is based on achieving the
                best match to the individual project type and its objectives.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
