import { useState, useEffect } from "react";

const useCurrentYear = () => {
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  return year;
};

export default useCurrentYear;
