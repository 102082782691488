import React from "react";

const Project = () => {
  return (
    <section class="project-area section-padding" id="project">
      <div class="container">
        <div class="row">
          <div
            class="
          col-md-6 col-lg-6 col-md-offset-3 col-lg-offset-3 col-sm-12 col-xs-12
        "
          >
            <div class="area-title text-center wow fadeIn">
              <h2>Our Projects</h2>
              <p>
                Build trust and loyalty through uncompromising honesty and
                integrity.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div class="project-menu wow fadeIn">
              <ul>
                <li class="filter active" data-filter="*">
                  All
                </li>
                {/* <li class="filter" data-filter=".appartment">appartment</li>
            <li class="filter" data-filter=".corporate">corporate</li>
            <li class="filter" data-filter=".restaurant">restaurant</li> */}
              </ul>
            </div>
          </div>
        </div>
        <div class="row project-gallery">
          <div class="single-project col-md-4 col-lg-4 col-sm-6 col-xs-12 corporate">
            <div class="single-project-details">
              <div class="project-img">
                <img src="img/img_4.jpg" alt="" />
              </div>
              <div class="project-details">
                <div class="project-view-details">
                  <a href="#">
                    <i class="bi bi-link"></i>
                  </a>
                  <a
                    class="project-big-thumb"
                    href="img/img_4.jpg"
                    data-effect="mfp-zoom-in"
                  >
                    <i class="bi bi-expand"></i>
                  </a>
                </div>
                <h4>Maintenance and installations</h4>
                <h5>Civil engineering</h5>
              </div>
            </div>
          </div>
          <div
            class="
          single-project
          col-md-4 col-lg-4 col-sm-6 col-xs-12
          restaurant
          appartment
        "
          >
            <div class="single-project-details">
              <div class="project-img">
                <img src="img/img_2.jpg" alt="" />
              </div>
              <div class="project-details">
                <div class="project-view-details">
                  <a href="#">
                    <i class="bi bi-link"></i>
                  </a>
                  <a
                    class="project-big-thumb"
                    href="img/img_2.jpg"
                    data-effect="mfp-zoom-in"
                  >
                    <i class="bi bi-expand"></i>
                  </a>
                </div>
                <h4>Demolition and site clearing</h4>
              </div>
            </div>
          </div>
          <div class="single-project col-md-4 col-lg-4 col-sm-6 col-xs-12 corporate">
            <div class="single-project-details">
              <div class="project-img">
                <img src="img/img_7.jpg" alt="" />
              </div>
              <div class="project-details">
                <div class="project-view-details">
                  <a href="#">
                    <i class="bi bi-link"></i>
                  </a>
                  <a
                    class="project-big-thumb"
                    href="img/img_7.jpg"
                    data-effect="mfp-zoom-in"
                  >
                    <i class="bi bi-expand"></i>
                  </a>
                </div>
                <h4>Rubble removal and waste management</h4>
              </div>
            </div>
          </div>
          <div class="single-project col-md-4 col-lg-4 col-sm-6 col-xs-12 appartment">
            <div class="single-project-details">
              <div class="project-img">
                <img src="img/img_6.jpg" alt="" />
              </div>
              <div class="project-details">
                <div class="project-view-details">
                  <a href="#">
                    <i class="bi bi-link"></i>
                  </a>
                  <a
                    class="project-big-thumb"
                    href="img/img_6.jpg"
                    data-effect="mfp-zoom-in"
                  >
                    <i class="bi bi-expand"></i>
                  </a>
                </div>
                <h4>Roads works rehabilitation of gravel roads</h4>
              </div>
            </div>
          </div>
          <div
            class="
          single-project
          col-md-4 col-lg-4 col-sm-6 col-xs-12
          restaurant
          corporate
        "
          >
            <div class="single-project-details">
              <div class="project-img">
                <img src="img/img_0.jpg" alt="" />
              </div>
              <div class="project-details">
                <div class="project-view-details">
                  <a href="#">
                    <i class="bi bi-link"></i>
                  </a>
                  <a
                    class="project-big-thumb"
                    href="img/img_0.jpg"
                    data-effect="mfp-zoom-in"
                  >
                    <i class="bi bi-expand"></i>
                  </a>
                </div>
                <h4>Bulk earth works</h4>
              </div>
            </div>
          </div>
          <div
            class="
          single-project
          col-md-4 col-lg-4 col-sm-6 col-xs-12
          restaurant
          appartment
        "
          >
            <div class="single-project-details">
              <div class="project-img">
                <img src="img/img_5.jpg" alt="" />
              </div>
              <div class="project-details">
                <div class="project-view-details">
                  <a href="#">
                    <i class="bi bi-link"></i>
                  </a>
                  <a
                    class="project-big-thumb"
                    href="img/img_5.jpg"
                    data-effect="mfp-zoom-in"
                  >
                    <i class="bi bi-expand"></i>
                  </a>
                </div>
                <h4>Asphalt works, roads</h4>
                <h5>Supply lay</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Project;
