import React, { useState, useEffect } from "react";
import Preloader from "../../screens/Home/components/Preloader/Preloader";
import ScrollToTop from "../../screens/Home/components/ScrollToTop/ScrollToTop";
import PushMenu from "../../screens/Home/components/Menu/PushMenu";
import Contact from "../../screens/Home/components/Contact/Contact";
import Footer from "../../screens/Home/components/Footer/Footer";
import About from "../../screens/Home/components/About/About";
import Header from "../../screens/Home/components/Header/Header";
import Services from "../../screens/Home/components/Services/Services";
import Project from '../../screens/Home/components/Project/Project'

const Layout = () => {
  const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    // Set a 5-second timeout to hide the preloader
    const timer = setTimeout(() => setShowPreloader(false), 5000);

    // Clear timeout if the component unmounts before the timer completes
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/* Conditionally render Preloader */}
      {showPreloader && <Preloader />}

      {/* Main content */}
      <ScrollToTop />
      <PushMenu />
      <Header />
      <About />
      <Services />
      <Project />
      <Contact />
      <Footer />
    </>
  );
};

export default Layout;
